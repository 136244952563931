import { UserInfo } from "@api/api";
import { useEffect, useState } from "react";
import keycloak from "../Keycloak";
import { useKeycloak } from "@react-keycloak/web";

export const useUserProfile = () => {
  const { initialized } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserInfo | undefined>();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await keycloak.loadUserInfo();
      setUserProfile(profile as UserInfo);
    };

    if (initialized) {
      fetchUserProfile().catch((err) => {
        console.error(err);
      });
    }
  }, [initialized]);

  return userProfile;
};
